<template>
  <div class="step-mask-box" :style="{ display: this.visible ? 'block' : 'none' }">
    <div class="step-mask"></div>
    <div class="step-content">
      <div class="container">
        <div v-if="stepsData.length > 0" class="content" id="guide-content">
          <template v-for="(item, index) of stepsData">
            <div class="custom-step" v-if="item.type === 'custom'" :key="index" :style="{ display: step === index ? 'block' : 'none' }">
              <div :class="['content', item.qrcodetype ? 'qrcode-wrapper' : '']">
                <h1>{{ item.popover.title }}</h1>
                <p>{{ item.popover.description }}</p>
                <div v-if="item.qrcodetype" class="qrcode-box">
                  <div>
                    <img src="../../assets/android.png" />
                    <div>{{ $t('guideBox.androidDownload') }}</div>
                  </div>
                  <div>
                    <img src="../../assets/ios.png" />
                    <div>{{ $t('guideBox.iosDownload') }}</div>
                  </div>
                  <div>
                    <img src="../../assets/google-play.png" />
                    <div>Google Play</div>
                  </div>
                </div>
                <template v-else>
                  <template v-if="item.current == 'entity' && openGuide != '1'">
                    <img class="cover" :src="item.popover.cover" />
                  </template>
                  <template v-else>
                    <template v-if="$store.getters.selfregister">
                      <img class="cover" :src="item.popover.cover" />
                      <span @click="openEntityPage" :class="['steps-one', $store.getters.lang]"></span>
                      <span @click="openAccountPage" :class="['steps-two', $store.getters.lang]"></span>
                      <span @click="openLocationPage" :class="['steps-three', $store.getters.lang]"></span>
                      <span @click="openCreatePage" :class="['steps-four', $store.getters.lang]"></span>
                    </template>
                    <template v-else-if="$store.getters.isOperate">
                      <!-- 数据管理员 -->
                      <img class="cover" :src="item.popover.cover" />
                      <span @click="openAccountPage" :class="['admin-steps-one', $store.getters.lang]"></span>
                      <span @click="openLocationPage" :class="['admin-steps-two', $store.getters.lang]"></span>
                      <span @click="openCreatePage" :class="['admin-steps-three', $store.getters.lang]"></span>
                    </template>
                    <template v-else-if="$store.getters.ispemgr">
                      <!-- 物业经理 -->
                      <img class="cover" :src="item.popover.cover" />
                      <span @click="openCreatePage" :class="['admin-steps-one', $store.getters.lang]"></span>
                    </template>
                  </template>
                </template>
              </div>

              <div v-if="item.showButtons" class="step-control">
                <button class="close-btn" @click="handleClose" v-if="!itemShowCloseButtons(item)">
                  <i class="el-icon-circle-close"></i>
                  <span>{{ $t('common.close') }}</span>
                </button>
                <el-button @click="handlePrevious(index)" :style="{ display: index === 0 ? 'none' : 'block' }">{{ $t('guideBox.preStep') }}</el-button>
                <el-button @click="handleNext(index)">{{ step === stepCount - 1 ? $t('guideBox.guideFinish') : $t('guideBox.nextStep') }}</el-button>
                <el-button @click="rebuild" v-if="$route.path.indexOf('account') > -1 || $route.path.indexOf('location') > -1">{{ $route.path.indexOf('account') > -1 ? $t('guideBox.guideAccountRepeatedly') : $t('guideBox.guidePointSrepeatedly') }}</el-button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../../common/driver/driver.min.css';
//
@import './index.scss';
</style>
