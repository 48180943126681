import Driver from "@/common/driver/driver.min.js";
export default {
  name: 'GuideBoxComponent',
  props: {
    value: {
      type: Number,
      default: 1,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    // 步骤配置
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      $driverDom: null,
      firstCreateStatus: true,
      step: this.value, // 当前步数(从0开始，第一步值为0，第二步值为1)
      stepsData: [], // 步骤数据
      stepCount: 0, // 总步数
      loading: false, // 是否正在加载向导
    };
  },
  created() {
    // mock 请求数据
    this.$driverDom = new Driver({
      // animate: false,
      className: 'scoped-class',
      animate: true,
      opacity: 0,
      padding: 10,
      allowClose: false,
      overlayClickNext: false,
      doneBtnText: this.$t('guideBox.nextStep'),
      closeBtnText: this.$t('common.close'),
      stageBackground: '#ffffff',
      nextBtnText: this.$t('guideBox.nextStep'),
      prevBtnText: this.$t('guideBox.preStep'),
      showButtons: true,
      keyboardControl: true,
      scrollIntoViewOptions: {},
      onHighlightStarted: () => { },
      onHighlighted: () => { },
      onDeselected: () => { },
      // 向导结束
      onReset: () => {
        // this.$nextTick((_) => {
        //   if (this.loading) return;
        //   // 判断销毁driver组件时的状态
        //   if (this.stepCount > 0) {
        //     const currentStepData = this.stepsData[this.step];
        //     // 如果当前处于运行 driver组件 的状态，则判断为手动点击关闭按钮，关闭整个向导
        //     // if (currentStepData.type === 'driver') {
        //     // 	alert('423')
        //     // 	this.handleClose();
        //     // }
        //   }
        // });
      },
      onNext: () => { },
      onPrevious: () => { },
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.createStepsInstances();
      document.querySelector("body").addEventListener("click", (event) => {
        if (event.target.className && event.target.className.indexOf('driver-close-btn') > -1) {
          if (this.loading) return;
          // 判断销毁driver组件时的状态
          if (this.stepCount > 0) {
            const currentStepData = this.stepsData[this.step];
            if (currentStepData.type === 'driver') {
              this.handleClose();
            }
          }
        }

      });

    });
  },
  watch: {
    value(value) {
      this.step = value;
    },
    steps() {
      this.$emit('input', 0);
      this.createStepsInstances();
    },
  },
  computed: {
    openGuide() {
      return sessionStorage.getItem('openGuide')
    }
  },
  methods: {
    itemShowCloseButtons(item){
      return typeof item.showCloseButtons == 'boolean' && item.showCloseButtons == false
    },
    createStepsInstances() {
      if (this.steps.length < 1) return;
      this.loading = true;
      const firstGuideType = this.steps[0].type;
      const typeList = ['custom', ' driver'];
      if (firstGuideType === 'driver') {
        // 当第一步为 driver 时判断该元素是否存在于页面(元素不存在时组件显示会报错)
        const el = this.steps[0].element;
        const dom = document.querySelector(el);
        if (!dom) {
          console.log('传参错误结束向导');
          this.handleClose();
          return;
        }
      } else if (!typeList.includes(firstGuideType)) {
        console.log('传参错误结束向导');
        // 当第一步 type 不属于任何类型时结束向导
        this.handleClose();
        return;
      }
      this.stepsData = this.steps.map((item, index) => {
        const stepPane = {
          ...item,

        };
        if (stepPane.type === 'custom') {
          stepPane.element = '#guide-content';
          // eslint-disable-next-line
          if (!stepPane.hasOwnProperty('showButtons')) {
            stepPane.showButtons = true;
          }
        } else if (stepPane.type === 'driver') {
          let videosHtmlArr = []
          if (item.voids) {
            item.voids.forEach(element => {
              videosHtmlArr.push(`<p><a href="${element.link}" target="_blank">${element.coursename}</a></p>`)
            })
          }
          
          const domItem = document.querySelector(item.element);
          
          const isElementDisplayed = (element) => {
            return element.offsetParent !== null;
          }
          if (!isElementDisplayed(domItem)) {
            
            this.$pubsub.publish('menuOpenedChangeEvent');
          }
          if (!stepPane.popover.title) {
            stepPane.popover.title = '<h4></h4>';
          }
          if (stepPane.popover.position) {
            stepPane.popover.title += `<div class="driver-guide--npc is-${stepPane.popover.position}"><img src="${require(`@/assets/guide-npc-${stepPane.popover.position}.png`)}"></div>`;
          } else {
            stepPane.popover.title += `<div class="driver-guide--npc"><img src="${require('@/assets/guide-npc-left.png')}"></div>`;
            if (item.element && item.element.indexOf('-btn') > -1) {
              stepPane.popover.position = 'left'
            }

          }
          if (stepPane.popover.description && videosHtmlArr != '') {
            stepPane.popover.description += `<div>${videosHtmlArr.join('')}</div>`
          }
          stepPane.onPrevious = () => {
            this.$driverDom.preventMove();
            this.handlePrevious(index);
          };
          stepPane.onNext = () => {
            this.$driverDom.preventMove();
            this.handleNext(index);
          };
        } else {
          console.warn('guide config error');
        }
        return stepPane;
      });
      this.stepCount = this.stepsData.length;
      this.$nextTick(() => {
        this.$driverDom.defineSteps(this.stepsData);
        this.runGuide();
      });
    },
    runGuide() {
      if (this.stepsData.length < 1) return;
      if (this.stepsData[0].type === 'driver') {
        this.$driverDom.start();
        this.disableGuideDriver(this.stepsData[0]);
      }
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    // 隐藏向导 driver组件
    hideGuideDriver() {
      this.$driverDom.reset();
      this.$emit('update:visible', false)
    },
    // 设置向导 driver禁止点击
    // eslint-disable-next-line
    disableGuideDriver(step) {
      // const guideHighEl = document.getElementById('driver-highlighted-element-stage');
      // if (step && step.disabled) {
      //   guideHighEl.classList.add('is-disabled');
      // } else {
      //   guideHighEl.classList.remove('is-disabled');
      // }
    },
    handlePrevious(step) {
      if (this.step < 1) return;
      const prevStepPane = this.stepsData[step - 1];
      if (prevStepPane.type === 'driver') {
        this.$emit('input', this.step - 1);
        if (this.$driverDom.isActivated) {
          this.$driverDom.movePrevious();
        } else {
          this.$driverDom.start(step - 1);
        }
        this.disableGuideDriver(prevStepPane);
      } else {
        this.$emit('input', this.step - 1);
        this.hideGuideDriver();
      }
      this.$nextTick(() => {
        if (this.step === 0 && prevStepPane !== 'driver') this.$emit('update:visible', true);
        this.$emit('prev-click', this.step);
      });
    },
    handleNext(step) {
      // 翻页
      if (this.step < this.stepCount - 1) {
        // 数据先走，后改view
        this.$emit('input', this.step + 1);
        const nextStepPane = this.stepsData[step + 1];
        if (nextStepPane.type === 'driver') {
          if (this.$driverDom.isActivated) {
            
            this.$driverDom.moveNext();
          } else {
            this.$driverDom.start(step + 1);
          }
          this.disableGuideDriver(nextStepPane);
        } else {
          this.hideGuideDriver();
        }
        this.$nextTick(() => {
          this.$emit('next-click', this.step);
        });
      } else {
        // 最后一步
        this.$nextTick(() => {
          this.$emit('finish', this.step, this.steps[this.step]);
          this.hideGuideDriver();
          // this.handleClose();
          console.log('关闭向导');
          // this.$emit('update:visible', false);
          if (this.step === this.stepCount - 1) this.$emit('finish', this.step, this.steps[this.step]);
          if (this.steps[this.step].current !== 'create' || this.$route.path.indexOf('property_list') > -1) {
            if (this.steps[this.step].current == 'entity') {

              this.$pubsub.publish('createPropertyEvent');
            } else {
              this.$router.push('/' + (this.steps[this.step].current == 'home' ? 'property_list' : this.steps[this.step].current))
            }

          } else if (this.$route.path.indexOf('create') == -1 && this.steps[this.step].current == 'create') {
            if (this.steps[this.step].current == 'entity') {

              this.$pubsub.publish('createPropertyEvent');
            } else {
              this.$router.push('/' + this.steps[this.step].current)
            }

          }
        });
      }
    },
    handleClose() {
      console.log('关闭向导');
      this.$emit('update:visible', false);
      if (this.step === this.stepCount - 1) this.$emit('finish', this.step, this.steps[this.step]);
      this.$emit('close');
    },
    cancelClose() {
      console.log('取消向导');
      this.$emit('update:visible', false);
    },
    nextStep() {
      this.$driverDom.moveNext();
    },
    openEntityPage(){
      this.$pubsub.publish('createPropertyEvent');
    },
    openAccountPage(){
      this.$router.push('/account')
    },
    openLocationPage(){
      this.$router.push('/location')
    },
    openCreatePage(){
      this.$router.push('/create')
    },
    rebuild(){
      this.$emit('update:visible', false);
      this.$emit('rebuild', this.stepsData[this.step])
    }
    
    
  }
}